<template>
	<Menu />
	
	<div>
		<vueper-slides autoplay :arrows="false">
			<vueper-slide :image="state.slides[0].image"></vueper-slide>
			<vueper-slide :image="state.slides[1].image"></vueper-slide>
			<vueper-slide :image="state.slides[2].image"></vueper-slide>
			<vueper-slide :image="state.slides[3].image"></vueper-slide>
		</vueper-slides>

		<div class="md:grid grid-cols-3 bg-rojo p-12 px-6 space-y-12 md:space-y-0 bg-repeat" :style="{ backgroundImage: 'url(' + require('@/assets/grandes-ideas/brushed.png') + ')' }">
			<div>
				<img class="h-44 md:h-48 mx-auto" :src="require('@/assets/grandes-ideas/galardones_internacionales.png')" alt="">
			</div>
			<div>
				<img class="h-44 md:h-48 mx-auto" :src="require('@/assets/grandes-ideas/certificacion_ecovadis.png')" alt="">
			</div>
			<div>
				<img class="h-44 md:h-48 mx-auto" :src="require('@/assets/grandes-ideas/galardones_nacionales.png')" alt="">
			</div>
		</div>

		<div class="md:grid grid-cols-3 py-10 md:py-6 gap-4 space-y-6 md:space-y-0 bg-repeat" style="{ backgroundImage: 'url(' + require('@/assets/grandes-ideas/dark-circles.png') + ')' }">
			<div>
				<img class="h-52 md:h-56 mx-auto" :src="require('@/assets/grandes-ideas/premium_1.jpg')" alt="">
			</div>
			<div>
				<img class="h-52 md:h-56 mx-auto" :src="require('@/assets/grandes-ideas/premium_2.jpg')" alt="">
			</div>
			<div>
				<img class="h-52 md:h-56 mx-auto" :src="require('@/assets/grandes-ideas/premium_3.jpg')" alt="">
			</div>
		</div>
	</div>
</template>

<script>
import Menu from '@/components/core/Menu'
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import { reactive } from 'vue'

export default {
	components: {
		Menu,
		VueperSlides, 
		VueperSlide
	},
	setup() {
		const state = reactive({
			slides: [
				{ image: require('@/assets/grandes-ideas/sliders/contreau.jpg') },
				{ image: require('@/assets/grandes-ideas/sliders/heineken.jpg') },
				{ image: require('@/assets/grandes-ideas/sliders/kfc.jpg') },
				{ image: require('@/assets/grandes-ideas/sliders/martell.jpg') }
			]
		});

		return {
			state
		}
	}
}
</script>
